import React, { useEffect } from 'react';
import Navbar from './components/Navbar/navbar';
import './App.css';
import PromotionBanner from './components/PromotionBanner/PromotionBanner';

const App = () => {
    const imageUrls = [
        `${process.env.PUBLIC_URL}/pdf_images/Artboard 1.png`,
        `${process.env.PUBLIC_URL}/pdf_images/Artboard 2.png`,
        `${process.env.PUBLIC_URL}/pdf_images/Artboard 3.png`,
        `${process.env.PUBLIC_URL}/pdf_images/Artboard 4.png`,
        `${process.env.PUBLIC_URL}/pdf_images/Artboard 5.png`,
        `${process.env.PUBLIC_URL}/pdf_images/Artboard 6.png`,
        `${process.env.PUBLIC_URL}/pdf_images/Artboard 7.png`,
        `${process.env.PUBLIC_URL}/pdf_images/Artboard 8.png`,
        `${process.env.PUBLIC_URL}/pdf_images/Artboard 9.png`,
        `${process.env.PUBLIC_URL}/pdf_images/Artboard 10.png`,
        // Add more image paths as needed
    ];

    useEffect(() => {
      // Disable right-click context menu
      const handleContextMenu = (event) => {
          event.preventDefault();
      };

      document.addEventListener('contextmenu', handleContextMenu);
      return () => {
          document.removeEventListener('contextmenu', handleContextMenu);
      };
  }, []);

  return (
    <div>
            <Navbar />
            <PromotionBanner />
            <div className="image-container">
                {imageUrls.map((url, index) => (
                    <div key={index} className="image-wrapper">
                        <img src={url} alt={`Page ${index + 1}`} className="responsive-image" />
                        <div className="overlay"></div>
                    </div>
                ))}
            </div>
        </div>
  );
};

export default App;